/* 
* Element we use to apply the shadow.
* You can use any element instead. Just note 
* it requires the use of `position: relative` 
* and some `background`.
*/
.box {
    /* Required properties */
    position: relative;

    /* Use  your own styles here */

    z-index: 1;
}

.box:before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(-45deg, #ff3d00 0%, #0400ff 100%);
    transform: translate3d(0px, 0px, 0) scale(1.15);
    filter: blur(180px);
    opacity: var(0.2);
    transition: opacity 0.3s;
    border-radius: inherit;
}

/* 
* Prevents issues when the parent creates a 
* stacking context. (For example, using the transform
* property )
*/
.box::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    border-radius: inherit;
}

.typewriter p {
    overflow: hidden;
    /* Ensures the content is not revealed until the animation */
    border-right: .15em solid orange;
    /* The typwriter cursor */
    white-space: nowrap;
    /* Keeps the content on a single line */
    /* Gives that scrolling effect as the typing happens */
    /* Adjust as needed */
    animation:
        typing 1.5s steps(40, end),
        blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

/* The typewriter cursor effect */
@keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: #fff;
    }
}

.basic {
    /* box-shadow: 0px 0px 10px 5px orange; */
    -webkit-filter: drop-shadow(0px 0px 150px #3C5EF9);
  filter: drop-shadow(0px 0px 150px #3C5EF9);
  }