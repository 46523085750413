@font-face {
    font-family: "Open Sans";
    src: url("https://fonts.googleapis.com/css?family=Open+Sans");
}

html {
    font-family: "Space Mono",  system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    scroll-behavior: smooth !important;
}

body {
}

a {
    cursor: pointer;
}

a:hover {
    /* opacity: 0.8; */
}

.shadowDiv {
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    border: 1px solid #E0E0E0 !important;
}

.shadowDivDark {
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    border: 1px solid #212121 !important;
}

.container {
    background: #ffffff !important;
    position: absolute !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.vertcenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.onlyvertcenter {
    display: flex;
    align-items: center;
}

/* .hero {
    -webkit-backdrop-filter: blur(80px) brightness(120%) contrast(80%);
    backdrop-filter: blur(80px) brightness(120%) contrast(80%);
} */

.hero {
    filter: blur(80px);
    -webkit-filter: blur(68px);
    -moz-filter: blur(80px);
    -ms-filter: blur(80px);
    -o-filter: blur(80px);
}